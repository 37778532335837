/* General Containers and sizing */
body * {
	font-family: 'Lato', sans-serif;
	letter-spacing: 0.4px;
}
.nowrap { white-space: nowrap; }

.container-fluid, .container-fluid-s, .container-fluid-m, .container-fixed { 
	padding-right: 15px; 
	padding-left: 15px;
	margin-left: auto;
	margin-right: auto;}

@media (min-width: 992px) {
	.container-fluid { width: 80vw;}
	.container-fluid-m { width: 70vw;}
	.container-fluid-s { width: 60vw;}
}
@media (min-width: 1300px) {
	.container-fluid { width: 70vw;}
}

.w-75 {
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}
.mt-6 {
	margin-top:  5rem!important;
}
.mb-4 {
	margin-bottom: 2rem !important;
}

.text-med p {
	font-size: 16px;
	margin-bottom: 1.5rem;
}

.text-gray {
	color: darkgray;
}
.color-light *:not(.btn) {
	color: #6d6e71;
    line-height: 26px;
}
.color-light .menu-item {
	color: #111111;
}
.notFoundLink:hover { color: gray!important;}


/* Header CSS */
header {flex-direction: column; justify-content: center;}
header > div {
	margin-right: auto;
    margin-left: auto;
}
.header-contact { flex-direction: column; }
.small-hidden { display: none; 	}

#headerLogo { max-width: 246px;}
@media (max-width: 524px) {
	#headerLogo {width: 50vw;}
}

@media (min-width: 576px) {
	.small-hidden { display: block; }
	.header-contact { flex-direction: row; }
}

@media (min-width: 768px) {
	header {flex-direction: row; justify-content: space-between;}
	header > div {
		margin-right: 0;
		margin-left: 0;
	}
}



/* Navbar Responsiveness */
.dropdown-toggle {
	color: var(--bs-navbar-active-color);
  }
.nav-link:hover {
	color: rgba(255, 255, 255, 0.75)!important;
}

@media (max-width: 576px) {
	.dropdown-menu .dropdown-menu{
		margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
	}
	.dropdown-menu > li:hover > .submenu{ display: block; }
  }	

@media all and (min-width: 576px) {
	.dropdown-menu li{ position: relative; 	}
	.nav-item .submenu{ 
		display: none;
		position: absolute;
		left:100%; top:-7px;
	}
	.nav-item .submenu-left{ 
		right:100%; left:auto;
	}
	.dropdown-menu > li:hover{ background-color: rgba(255, 255, 255, 0.15); }
	.dropdown-menu > li:hover > .submenu{ display: block; }
}	



/* Cover Images */
.home-cover, .contact-cover, .about-cover {
	background-position: center !important; 
  	background-repeat: no-repeat !important; 
  	background-size: cover !important;
	position: relative;
	box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
}

.home-cover-text { top: 40%; }
.home-cover-text::before, .home-cover-text::after {
    content: '';
	width: 108%;
	border-bottom: solid 1.5px white;
	position: absolute;
	left: -4%;
}
.home-cover-text::before {top: 0;}
.home-cover-text::after {bottom: 0;}

.contact-cover-text, .about-cover-text { top: 50%; }

.home-cover {
  background: url("./assets/home_cover.jpg");
  height: 55vw;
}
.contact-cover  {
	background-image: url("./assets/contact_cover.jpg");
	height: 45vw;
}
.about-cover {
	background-image: url("./assets/about_cover.jpg");
	height: 45vw;
}

@media (min-width: 576px) {

}
@media (min-width: 768px) {
	.home-cover { height: 65vh; }
	.contact-cover { height: 55vh; }
	.about-cover { height: 55vh; }
}

@font-face {
	font-family: Ostrich;
	src: url("assets/ostrich-regular.ttf");
}

.cover-text {
	color:floralwhite;
	text-align: center;
	font-family: Ostrich;
	text-shadow: 0.1vw 0px white, -0.05vw 0px white, -5px 0px 10px black, 5px 0px 10px black;
	transform: translate(-50%, -50%) scale(0.8, 1);
	font-size: 8vw;
	letter-spacing: 0.8vw;
	position: absolute;
	left: 50%;
	white-space: nowrap;
	user-select: none;
}
	/* Button and lines are just for the home cover */
.cover-button {
	background-color: rgba(255, 255, 255, .6);
	box-shadow: 10px 10px 10px #00000063;
	color: #893f0c;
	text-shadow: 0.08vw 0px #893f0c, -0.08vw 0px #893f0c;
	transition-duration: 0.4s;
	display: inline-flex;
  	align-items: center;
	justify-content:center;
	top: 65%;
	width: 34vw;
	height: 8vw;
	border: none;
	padding-left: 1.5vw;
}
.cover-button:hover {
	background-color: rgba(171, 171, 171, 0.7);
  }
.cover-line {
	position: absolute;
  	left: 50%;
	width: 37vw;
	height: 0.1vw;
	transform: translate(-50%, -50%);
	background-color: floralwhite;
}
.cover-line.top-line {top: 33%;}
.cover-line.bottom-line {top: 47%;}

@media (min-width: 576px) {
	.cover-text {
		font-size: 6vw;
	}
	.cover-button {
		width: 28vw;
		height: 6vw;
	}
}
@media (min-width: 1000px) {
	.cover-text {
		font-size: 4vw;
	}
	.cover-button {
		width: 22vw;
		height: 5vw;
	}
}


/* Home Page Css */ 
.blurb-padding { padding: 1rem; }
@media (min-width: 450px) { .blurb-padding { padding: 2rem; }}
@media (min-width: 630px) { .blurb-padding { padding: 3rem; }}

.homeImgSm {
	height: 200px;
	width: 200px;
	float: left;
	margin-top: 5px;
	margin-right: 20px;
	border-radius: 50%;
}
@media (max-width: 630px) { 
	.homeImgSm { display: none;}
}
@media (min-width: 1000px) {
	.homeImgSm { margin: 10px 0; }
}

.card-text {
	color: white;
	position: absolute;
	font-size: 1.3rem;
	bottom: 6%;
	left: 7%
}
@media (max-width: 576px) {
	.card-container {
		margin: auto;
		max-width: 360px;
	}
}



/* About Page Css */
.aboutImgL, .aboutImgR {
	height: 350px;
	margin-top: 5px;
	border: 2px solid #444040;
}
.aboutImgL {
	float: left;
	margin-right: 20px;
}
.aboutImgR {
	float: right;
	margin-left: 20px;
}
.about-text {
	min-height: 355px;
}
@media (max-width: 550px) {
	.aboutImgL {height: 250px}
	.aboutImgR {height: 250px}
}
@media (max-width: 420px) {
	.aboutImgL, .aboutImgR {
		float: none;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}
}
	/* For featured links in About page */
.content-links a{
	line-height: 0px;
	margin-bottom: 0;
	font-size: 18px;
}
.content-links a:hover {
	color: black;
}



/* Catering Pages Css */
.cateringImg {
	height: 250px;
	float: right; 
	margin: 0 0 20px 20px;
}

.event-img {
	max-width: 300px;
}
.event-img > img {
	width: 60%; 
	max-width: 275px;
}
@media (min-width: 576px) { 
	.event-img > img{ width: 100%; }
}
@media (min-width: 700px) {
	.cateringImg { height: 350px; }
}
@media (max-width: 500px) {
	.cateringImg { display: none;}
}

	/* For links in the middle of paragraphs */
.inline-link {
	text-decoration: none;
	color: black;
	font-weight: 400;
}
.inline-link:hover {
	color:#6d6e71;
}
a.inline-link#weddingLink {
	font-size: 16px;
	text-decoration: none;
	color: black;
	font-weight: 400;
}
a.inline-link#weddingLink:hover{
	color:#6d6e71;
}



/* Gallery Page */
.image-gallery{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	list-style: none;
	padding: 0;
	margin-bottom: 40px;
  }
  
  .image-gallery img {
	object-fit: cover;
	width: 100%;
	height: 150px;
	vertical-align: middle;
	border-radius: 5px;
  }

.lg-outer .lg-thumb-item img {
	object-fit: contain!important;
}
@media (min-width: 320px) { 
	.container-fixed {width: 290px;}
}
@media (min-width: 490px) { 
	.container-fixed {width: 462px; margin-left: calc((70vw - 492px)/2);} 
	#galleryContainer.container-fluid-m { width: 70vw;}
}
@media (min-width: 720px) { 
	.container-fixed {width: 700px; margin-left: calc((70vw - 730px)/2);} 
}
@media (min-width: 1325px) { 
	.container-fixed {width: 1045px; margin-left: calc((70vw - 1075px)/2);} 
}

.carousel {
	margin-bottom: 10rem;
  }
  .carousel-caption {
	bottom: 3rem;
	z-index: 10;
  }
  
  .carousel-item {
	height: 32rem;
  }
  .carousel-item > img {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%,0%);
	height: 32rem;
  }

.accordion {
	--bs-accordion-active-color: white;
	--bs-accordion-btn-focus-border-color: white;
	--bs-accordion-btn-focus-box-shadow: 0 0 0 0.25 white;
}
  .accordion-button {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 0 0 0;
  }
  button.accordion-button:not(.collapsed) {
	color: inherit;
	background-color: inherit;
  }

.carousel-indicators {
	margin-bottom: -100px;
	margin-left: 0;
	margin-right: 0;
	justify-content: inherit;
}

.carousel-indicators button.galleryThumbnail {
	background-color: white;
	border: 1px lightgray solid;
	width: 100px;
	height: 68px;
	flex: 0 0 auto;
}
.galleryThumbnail img {
	object-fit: contain;
	padding: 2px;
	height: 100%;
	margin: auto;

}



/* Contact Page */
.btn {
	border-radius: 15px / 15px;
	min-width: 75px;
}

.info-col {
	flex: 0 0 auto; 
	width: 320px;
}
.message-col {
	flex: 1 0 0%;
}
@media (max-width: 992px) {
	.info-col {width: 280px;}
}
@media (max-width: 650px) { 
	.info-col, .message-col {
		flex: 0 0 auto;
		width: 100%;
	}
	.info-col > div {text-align: center;}
	.message-col > h4 {text-align: center; margin-top: 20px;}
}


.form-control::placeholder {
	color: #b7b7b7;
	opacity: 1;
}

#contact-form.submitted {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 1.5s, opacity 0.5s linear 0.7s;
}
.successMsg, .failedMsg{
	visibility: hidden;
	opacity: 0;
}
.successMsg.submitted, .failedMsg.submitted {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s linear 1.3s;
}

#formContainer svg, #formContainer h1, #formContainer h5 {
	position: absolute;
	transform: translate(-50%,-50%)!important;
}
#formContainer svg {
	top: 25%;
	left: 16%;
}
#formContainer h1 {
	top: 15%;
	left: 65%;
	text-align: center;
	white-space: nowrap;
	color: black;
	font-weight: bolder;
}
#formContainer h5 {
	top: 35%;
	left: 65%;
	width: 60%;
	text-align: center;
	color: black;
	font-weight: bolder;
}

#contactPin {
	transform: translate(0%,-50%);
}